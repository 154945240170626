<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'" />
        <div class="form-wrapper fertilizer-distribution">
            <b-overlay :show="loading">
                <b-card :title="$t('teaGardenDashboard.fertilizer_service')">
                </b-card>
                <!-- search -->
                <div class="mb-3" style="background: #214162;padding: 15px 10px 8px;color: #fff;margin: 0;border-radius: 5px;">
                    <b-row class="justify-content-center">
                        <!-- fiscal year list  -->
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group
                            label-for="fiscal_year_id"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </b-col>
                        <!-- course list  -->
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group
                            label-for="circular_id"
                            >
                            <template v-slot:label>
                                {{ $t('teaGardenPduService.circular_name') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.circular_id"
                                :options="circularList"
                                id="circular_id"
                                >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <b-button variant="primary" @click="searchData" size="sm" style="margin-top:18px">{{ $t('globalTrans.search') }}</b-button>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col sm="8">
                        <b-card>
                            <b-card-text>
                                <b-row>
                                <b-col sm="4">
                                    <router-link :to="{ name: 'tea_garden_service.btb.indent_submission_list', query: { circular_id: this.search.circular_id, not_status: [1], fiscal_year_id: search.fiscal_year_id, limit: 100 } }">
                                    <div class="info-card submitted">
                                        <div class="icon">
                                            <i class="ri-folder-received-fill"></i>
                                        </div>
                                        <div class="content">
                                            <p>{{ $t('teaGardenDashboard.total_indent_submitted') }}</p>
                                            <h3>{{ $n(totalInfo.totalApplicationSubmit) }}</h3>
                                        </div>
                                    </div>
                                    </router-link>
                                </b-col>
                                <b-col sm="4">
                                    <router-link :to="{ name: 'tea_garden_service.btb.indent_submission_list', query: { circular_id: this.search.circular_id, fiscal_year_id: search.fiscal_year_id, status: 10, limit: 100 } }">
                                    <div class="info-card approved">
                                        <div class="icon">
                                            <i class="ri-checkbox-fill"></i>
                                        </div>
                                        <div class="content">
                                            <p>{{ $t('teaGardenDashboard.approved_indent') }}</p>
                                            <h3>{{ $n(totalInfo.approvedApplication) }}</h3>
                                        </div>
                                    </div>
                                    </router-link>
                                </b-col>
                                <b-col sm="4">
                                    <router-link :to="{ name: 'tea_garden_service.btb.indent_submission_list', query: { circular_id: this.search.circular_id, fiscal_year_id: search.fiscal_year_id, status: 2, limit: 100 } }">
                                    <div class="info-card pending">
                                        <div class="icon">
                                            <i class="ri-time-fill"></i>
                                        </div>
                                        <div class="content">
                                            <p>{{ $t('teaGardenDashboard.pending_indent') }}</p>
                                            <h3>{{ $n(totalInfo.pendingApplication) }}</h3>
                                        </div>
                                    </div>
                                    </router-link>
                                </b-col>
                            </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card>
                            <b-card-text>
                                <b-row>
                                    <b-col cols="12">
                                        <div class="info-card left-card requested-far">
                                            <div class="content">
                                                <p>{{ $t('teaGardenDashboard.total_request_fertilizer') }}</p>
                                                <span>
                                                    <h3>{{ $n(totalInfo.totalFertilizerRequest) }}</h3>
                                                    <small>{{ $t('globalTrans.m_ton') }}</small>
                                                </span>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-share-circle-fill"></i>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <div class="info-card left-card approved-far mt-2">
                                            <div class="content">
                                                <p>{{ $t('teaGardenDashboard.total_receiped_fertilizer') }}</p>
                                                <span>
                                                    <h3>{{ $n(totalInfo.totalFertilizerReceived) }}</h3> <small>{{ $t('globalTrans.m_ton') }}</small>
                                                </span>
                                            </div>
                                            <div class="icon">
                                                <i class="ri-shield-check-fill"></i>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <b-card>
                    <div id="chart" class="px-3">
                        <h5 class="my-2">{{$t('teaGardenDashboard.approve_fertilizer_indent')}}</h5>
                        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                        <div class="tea-chart-number">
                            <p><i>{{ $t('teaGardenDashboard.fertilizer') }} {{$t('globalTrans.m_ton')}}</i></p>
                        </div>
                        <p class="tea-chart-year"><i>{{ $t('globalTrans.fiscal_year') }} ({{fiscalYearName(search.fiscal_year_id)}})</i></p>
                    </div>
                </b-card>
            </b-overlay>
        </div>
    </div>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    export default {
        name: 'FertilizerDistribution',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                search: {
                    fiscal_year_id: this.$store.state.currentFiscalYearId,
                    circular_id: 0
                },
                totalInfo: {
                    totalApplicationSubmit: 0,
                    pendingApplication: 0,
                    approvedApplication: 0,
                    totalFertilizerRequest: 0,
                    totalFertilizerReceived: 0
                },
                newSeriesData: [],
                categories: [],
                categoriesBn: []
            }
        },
        created () {
            this.search.circular_id = this.circularList ? this.circularList[0].value : 0
            this.loadData()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            fiscalYearList () {
                return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
            },
            circularList () {
                return this.$store.state.TeaGardenService.commonObj.btbFertilizerCircularList.filter(item => item.status === 1)
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            series () {
                return [{
                    name: `${this.$t('globalTrans.approved')}`,
                    data: this.newSeriesData
                }
            ]
            },
            chartOptions () {
                return {
                    chart: {
                        type: 'bar',
                        height: 450
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            distributed: true,
                            columnWidth: '20%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.currentLocale === 'en' ? this.categories : this.categoriesBn
                    },
                    fill: {
                        opacity: 1
                    },
                    colors: ['#77B6EA', '#B90E03', '#034092', '#F9EB17', '#172FF9', '#17F939', '#7D0EDA', '#755D58', '#2F2C61', '#9F4E40', '#9F3E40', '#304E40']
                }
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            searchData () {
                this.loadData()
            },
            fertilierNameEn (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => (item.value === id))
                return obj.text_en ? obj.text_en : ''
            },
            fertilierNameBn (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => (item.value === id))
                return obj.text_bn ? obj.text_bn : ''
            },
            async loadData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/fertilizer-distribution', this.search)
                if (result.success) {
                    this.totalInfo = result.data.totalInfo
                    const categoryArr = []
                    const categoryBnArr = []
                    const newSeriesArr = []
                    result.data.chartInfo.forEach(item => {
                        categoryArr.push(this.fertilierNameEn(item.fertilizer_id))
                        categoryBnArr.push(this.fertilierNameBn(item.fertilizer_id))
                        newSeriesArr.push(item.total_receipt)
                    })
                    this.categories = categoryArr
                    this.categoriesBn = categoryBnArr
                    this.newSeriesData = newSeriesArr
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            fiscalYearName (id) {
                const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => (item.value === id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
    }
</script>
<style scoped>
/* custom chart design  */
.tea-chart-number {
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-41px, -50%);
}
.tea-chart-number p {
    transform: rotate(-90deg);
    font-weight: 600;
}
.font-bn .tea-chart-number {
    transform: translate(-25px, -50%);
}
.tea-chart-year {
    text-align: center;
    margin: -12px 0 10px;
    font-weight: 600;
}
/* custom chart design  end*/

.fertilizer-distribution .info-card {
    margin: 35px 0 22px 0;
    color: #fff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fertilizer-distribution .info-card.left-card {
    margin: 0;
    color: #fff;
    padding: .5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fertilizer-distribution .info-card.left-card .content {
    padding: 0;
    text-align: left;
}
.fertilizer-distribution .info-card.left-card .content span {
    display: flex;
    align-items: center;
}
.fertilizer-distribution .info-card.left-card .icon {
    margin-top: 0;
    display: flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: transparent;
}
.fertilizer-distribution .info-card .icon {
    margin-top: -40px;
    display: flex;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.fertilizer-distribution .info-card .content {
    margin-top: 5px;
}

.fertilizer-distribution .info-card .content h3 {
    margin-top: 5px;
    padding: 3px;
    border-radius: 10px;
}
.fertilizer-distribution .submitted{
    background: linear-gradient(to top right, #07ABAD 0%, #00DADE 100%);
}
.fertilizer-distribution .approved{
    background: linear-gradient(to top right, #15C382 0%, #20EFA1 100%);
}
.fertilizer-distribution .pending{
    background: linear-gradient(to top right, #FE956A 0%, #FEB696 100%);
}
.fertilizer-distribution .requested-far{
    background: radial-gradient(circle farthest-corner at top left, #36C3ED 0%, #19D7E2 100%);
}
.fertilizer-distribution .approved-far{
    background: radial-gradient(circle farthest-corner at top left, #B398D6 0%, #F0B6E8 100%);
}
.fertilizer-distribution .submitted .icon{
    background: linear-gradient(to bottom left, #04a2a4 0%, #00DADE 100%);
}
.fertilizer-distribution .approved .icon{
    background: linear-gradient(to bottom left, #0db477 0%, #20EFA1 100%);
}
.fertilizer-distribution .pending .icon{
    background: linear-gradient(to bottom left, #f7865a 0%, #FEB696 100%);
}
.fertilizer-distribution .requested-far .icon{
    background: linear-gradient(to bottom left, #eb4457 0%, #FF8E99 100%);
}
.fertilizer-distribution .approved-far .icon{
    background: linear-gradient(to bottom left, #eb4457 0%, #FF8E99 100%);
}
</style>
